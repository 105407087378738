

































































import { Component, Vue } from 'vue-property-decorator';
import { api } from '@/api';
import { appName } from '@/env';
import { readUserCreateError } from '@/store/main/getters';
import { dispatchUserCreate } from '@/store/main/actions';

@Component
export default class Login extends Vue {
  public email: string = '';
  public password: string = '';
  public password2: string = '';
  public fullName: string = '';
  public usedInviteCode: string = '';

  public birthday: string = '';
  public phone_number: string = '';
  public physical_address: string = '';
  public ID_number: string = '';
  public U_source =  [];
  public used_CEX = [];
  public checkbox1: boolean = false;
  public checkbox2: boolean = false;
  public checkbox3: boolean = false;

  // public appName = appName;
  // public dialog = false;
  public valid = false;

  public U_source_choices = ['於臺灣交易所以新臺幣購買', '於國外交易所以信用卡購買', '與其他人交易或交換', '挖礦取得', '其他'];
  public CEX_choices = ['Maicoin/Max Exchange', '幣託 BitoEX/BitoPro', '王牌 Ace Exchange', '幣安 Binance', 'FTX', 'Bybit', '其他'];

  public birthdayRules() {
    return [(v) => !!v || '請填寫生日',
            (v) => /^\d{4}\/\d{1,2}\/\d{1,2}$/.test(v) || '請填寫以 / 分隔的日期']
  }

  public IDRules() {
    return [(v) => !!v || '請填寫身分證字號',
            (v) => /^[A-Z]\d{9}$/.test(v) || '請填寫正確的身分證字號']
  }

  public cellphoneRules() {
    return [(v) => !!v || '請填寫',
            (v) => /^\d{10}$/.test(v) || '請填寫 0 開頭共 10 碼的手機號碼']
  }

  public fullNameRules() {
    return [v => !!v || '請填寫你的真實姓名']
  }

  public emailRules() {
    return [v => !!v || '請填寫你的 Email',
            v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || '請填寫正確的 Email']
  }

  public passwordRules() {
    return [
      (v) => !!v || '請填寫密碼',
      (v) => ( !!v && v.length >= 8 ) || '密碼長度需大於 8 個字',
    ];
  }

  public moreThanOneRules() {
    return [v => v.length>0 || '請勾選至少一項']
  }

  public inviteCodeRules() {
    return [
      (v) => (!v || v.startsWith('rbt')) || '推薦碼不可用',
    ];
  }

  public notEmptyRules() {
    return [v => !!v || '請填寫']
  }

  public checkboxRules() {
    return [v => !!v || '請同意並勾選']
  }

  public get userCreateError() {
    return readUserCreateError(this.$store);
  }

  public submit() {
    if ((this.$refs.form as any).validate()) {
      dispatchUserCreate(this.$store, {email: this.email, password: this.password, full_name: this.fullName, used_invite_code: this.usedInviteCode,
      birthday: this.birthday, phone_number: this.phone_number, physical_address: this.physical_address, ID_number: this.ID_number, U_source: this.U_source, used_CEX: this.used_CEX});
    }
  }

  created () {
    let params = (new URL(document.location.href).searchParams);
    let code = params.get("c");

    if (code != null) {
      this.usedInviteCode = code;
    }

    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }
  }

  onResize () {
    this.isMobile = window.innerWidth < 960;
  }  
}
